@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400&display=swap");

@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css");

@import url("slick-carousel/slick/slick.css");
@import url("slick-carousel/slick/slick-theme.css");

* {
  font-family: "Karla", sans-serif;
  box-sizing: border-box;
  margin: 0 auto;
  letter-spacing: 0.1em;
}

/* -----NAV------------- */

.header--nav {
  position: fixed;
  z-index: 1000;
  opacity: 0.7;
  width: 100%;
}

.nav--container {
  display: flex;
  justify-content: space-between;
  background-color: rgb(29, 28, 28);
  color: rgb(211, 204, 204);
  opacity: 1;
}

.nav--logo {
  display: flex;
  align-items: center;
  margin: 1em;
}

.nav--ul {
  display: flex;
  flex-direction: row;
}

.nav--ul,
li {
  display: flex;
  margin: 1em;
  align-items: center;
}

.nav--il,
li:hover {
  cursor: pointer;
  color: rgb(76, 204, 204);
  transition: 0.5s;
  transition-timing-function: ease-in-out;
}

.devicon-github-original {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: rgb(211, 204, 204);
}

/* -----ABOUT----------- */

.about--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(29, 28, 28);
  color: rgb(211, 204, 204);
}

.about--title,
h1 {
  font-size: 2.2em;
  margin-left: 0.3em;
  color: rgb(228, 220, 220);
}

.about--info {
  width: 60%;
  margin-left: 2em;
  margin-top: 1em;
}

.about--info > p {
  /* letter-spacing: 0.1em; */
}

/* ------TECH -------- */

.tech--container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(29, 28, 28);
  background-color: rgb(211, 204, 204);
}

.tech--info {
  width: 60%;
  margin-left: 2em;
  margin-top: 1em;
}

.tech--title {
  color: rgb(29, 28, 28);
  margin-left: 1.5em;
}

.tech--h1 {
  font-size: 4.2em;
  margin-left: 0.1rem;
  color: rgb(29, 28, 28);
}

span {
  font-size: 2rem;
}

/* -----PROJECTS----------- */

.projects--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(29, 28, 28);
  color: rgb(211, 204, 204);
}

.projects--title,
h1 {
  font-size: 2.2em;
  margin-left: 0.25em;
}

.projects--h1 {
  color: rgb(211, 204, 204);
}

.projects--info {
  width: 60%;
  margin-left: 2em;
  margin-top: 1em;
}

.projects--info,
p {
  letter-spacing: 0.1em;
}

/*-----CONTACT FORM-----------*/

.contact-form-container {
  display: Flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: rgb(29, 28, 28);
  background-color: rgb(211, 204, 204);
}

.contact--info {
  width: 50%;
  margin-left: 2em;
  margin-top: 1em;
  flex: 1;
}

.contact--h1 {
  color: rgb(29, 28, 28);
  font-size: 4.2em;
  margin-left: 0.1rem;
}

.contact--form {
  display: flex;
  flex: 2;
  flex-direction: column;
  /* margin-left: 4em; */
}

.form--element--container {
  width: 80%;
  height: auto;
  margin: 20px;
  background-color: rgb(29, 28, 28);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  opacity: 0.9;
}

.form--element--container label {
  display: flex;
  justify-content: left;
  font-size: 1.25rem;
}

.form--element--container input {
  height: 40px;
  width: 80%;
  text-align: center;
  border-radius: 8px;
  border: none;
}

.form--element--container textarea {
  height: 100px;
  width: 80%;
  text-align: center;
  padding: 12px;
  margin-bottom: 16px;
  text-decoration: none;
  border-radius: 8px;
  border: none;
}

.form--element--container button {
  height: 36px;
  width: 40%;
  border-radius: 8px;
  border: none;
  background-color: rgb(90, 86, 86);
  color: white;
  transition: background-color 2s ease-out 100ms;
  transition: text-transform 2s ease-out 100ms;
}

.form--element--container button:hover {
  background-color: rgb(196, 189, 189);
  color: black;
}

/*-------ANIMATION ABOUT--------*/

.animate-square {
  border: 2px solid rgb(145, 150, 150);
  height: 40px;
  width: 40px;
  animation: animate 25s linear infinite;
  overflow: hidden;
  margin-left: 10px;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
    border: 2px solid rgb(145, 150, 150);
  }
  100% {
    transform: scale(1) translateY(-250px) rotate(360deg);
    opacity: 1;
    border: 2px solid lightcoral;
  }
}

.tech--icon-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.tech--icon-container i {
  font-size: 3rem;
  padding: 1rem;
  margin: 1rem;
}

.project--card {
  margin-top: 20px;
  height: 50vh;
  overflow: hidden;
  transition: 0.3s;
  color: white;
}

.project--card img {
  height: 50%;
  width: 50%;
  object-fit: contain;
}

.project--card--body {
  padding: 15px;
  width: 80%;
}

.project--info,
.project--title {
  margin: 10px 50px;
  text-align: center;
}

.carousel--container {
  width: 90%;
  height: 100%;
  margin-bottom: 50px;
  text-align: center;
}
